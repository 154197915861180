import { Link } from "react-router-dom";
import logosinestry from "../../../auth/Login/logo_gnpsinestry.svg";
import { React, useState } from "react";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import rolesPermisos from '../../../data/rolesPermisos'
import { FaChartPie } from "react-icons/fa";
import { GoDeviceCameraVideo } from "react-icons/go";


const Capacitacion = ({ nombre, mdstatus, eseditable }) => {
    return (
        <div className="container  justify-center">
            <div
                className="absolute top-[10vh] w-[60%] rounded-[2.5vw] shadow-gray-400 shadow -mt-[2vh] ml-[13vw] 
                       flex flex-col justify-center"
            >
                <div className="px-[20vw] pt-5">
                    <img src={logosinestry} alt="" />
                </div>
                <div className="w-full flex flex- justify-center">
                    <span className="text-[1.5rem] justify-center items-center font-bold text-titulo1LoginSinApp ">
                        Capacitación
                    </span>
                </div>
                <div className="w-full flex justify-left  ">
                    <div className="w-full gap-2 px-10">
                        <p className="py-1 text-justify text-[.8rem]">
                            Aquí encontrarás videos de capacitación,con la finalidad de manter un alto estandar de calidad y servicio profesional.
                        </p>
                        <p className="py-1 text-justify"></p>
                    </div>
                </div>
                <ul className="w-[100%] pl-5 items-left justify-start flex flex-col py-[2vh] text-[1.2rem]">
                <li className="w-[100%] ">
                        <Link to="https://youtu.be/kJ8lMxTHNEA" target="_blank" className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btninApp h-8 col-1 mr-5 w-[50vw] text-btntTxtBcoSinApp hover:text btntTxtSinApp rounded-[15vh]`}
                        >
                            <span
                                className={`text-[1.1rem] pl-6 pl-4 pt-2 block float-left`}
                            >
                                <GoDeviceCameraVideo />
                            </span>
                            <span
                                className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
                                1. Daños a dispositivos de seguridad
                            </span>


                        </Link>  </li>
                        <li className="w-[100%] ">
                        <Link to="https://youtu.be/Ei3IL81FppU" target="_blank" className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btninApp h-8 col-1 mr-5 w-[50vw] text-btntTxtBcoSinApp hover:text btntTxtSinApp rounded-[15vh]`}
                        >
                            <span
                                className={`text-[1.1rem] pl-6 pl-4 pt-2 block float-left`}
                            >
                                <GoDeviceCameraVideo />
                            </span>
                            <span
                                className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
                                2. Daños a la carpeta asfáltica, Zonas laterales y obras de drenaje
                            </span>


                        </Link>  </li>
                        <li className="w-[100%] ">
                        <Link to="https://youtu.be/hyizaYfexX4" target="_blank" className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btninApp h-8 col-1 mr-5 w-[50vw] text-btntTxtBcoSinApp hover:text btntTxtSinApp rounded-[15vh]`}
                        >
                            <span
                                className={`text-[1.1rem] pl-6 pl-4 pt-2 block float-left`}
                            >
                                <GoDeviceCameraVideo />
                            </span>
                            <span
                                className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
                                3. Daño a señalamiento vertical y horizontal
                            </span>


                        </Link>  </li>
                        <li className="w-[100%] ">
                        <Link to="https://youtu.be/d-LcGQWFijY" target="_blank" className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btninApp h-8 col-1 mr-5 w-[50vw] text-btntTxtBcoSinApp hover:text btntTxtSinApp rounded-[15vh]`}
                        >
                            <span
                                className={`text-[1.1rem] pl-6 pl-4 pt-2 block float-left`}
                            >
                                <GoDeviceCameraVideo />
                            </span>
                            <span
                                className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
                               4. Recomendaciones clave para atencíon de siniestros
                            </span>


                        </Link>  </li>
                        <li className="w-full ">
                        <Link to="https://youtu.be/k0a20tEJangs" target="_blank" className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btninApp h-8 col-1 mr-5 w-[50vw] text-btntTxtBcoSinApp hover:text btntTxtSinApp rounded-[15vh]`}
                        >
                            <span
                                className={`text-[1.1rem] pl-6 pl-4 pt-2 block float-left`}
                            >
                                <GoDeviceCameraVideo />
                            </span>
                            <span
                                className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
5. Importancia de evidencia fotográfica y documentacíón 
                            </span>


                        </Link>  </li>
                </ul>

            </div>

        </div>)
}
export default Capacitacion;